<template>
  <div class="aside-header__container">
    <div :class="['avatar-ctan', { collapse: isCollapse }]">
      <div>
        <img
          class="avatar"
          src="@/assets/images/avatar.png"
          width="45"
          height="45"
        />
      </div>
      <div class="nickname">
        <div>{{ $store.getters.userInfo.username }}</div>
        <div>
          <i class="fa fa-circle circle-icon"></i>
          <span>在线</span>
        </div>
      </div>
    </div>
    <el-menu
      :default-active="activeIndex"
      @select="handleSelect"
      unique-opened
      background-color="#222d32"
      text-color="#ffffff"
      router
      :collapse="isCollapse"
    >
      <template v-for="item in menus" :key="item.path">
        <el-sub-menu
          v-if="Array.isArray(item.children) && item.children.length"
          :index="item.path"
        >
          <template #title>
            <i :class="['menu-icon', item.meta['icon']]"></i>
            <span class="item-title">{{
              item.meta ? item.meta["title"] : ""
            }}</span>
          </template>
          <el-menu-item
            v-for="subItem in item.children"
            :index="item.path + '/' + subItem.path"
            :key="item.path + '/' + subItem.path"
            :route="item.path + '/' + subItem.path"
          >
            <template #title>
              <i :class="['menu-icon', subItem.meta['icon']]"></i>
              <span class="item-title">{{
                subItem.meta ? subItem.meta["title"] : ""
              }}</span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item v-else :index="item.path">
          <i :class="['menu-icon', item.meta['icon']]"></i>
          <span class="item-title">{{
            item.meta ? item.meta["title"] : ""
          }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    menus() {
      return this.$store.getters.menus;
    },
    activeIndex() {
      return this.$route.fullPath;
    },
    isCollapse() {
      return this.$store.getters.menuCollapse;
    },
  },
  created() {},
  mounted() {},
  methods: {
    handleSelect() {},
  },
};
</script>

<style lang="scss" scoped>
.aside-header__container {
  .avatar-ctan {
    display: flex;
    padding: 10px;
    flex-direction: row;
    height: 65px;
    overflow: hidden;

    .avatar {
      border-radius: 50%;
      margin-right: 15px;
      transition: all ease 0.5s;
      display: block;
    }
    .nickname {
      display: flex;
      flex-direction: column;
      font-size: 13px;
      color: #ffffff;

      > div:first-child {
        margin: 3px 0 5px;
        font-weight: 600;
      }

      .circle-icon {
        color: #18bc9c;
        margin-right: 3px;
      }
    }
  }
  .avatar-ctan.collapse {
    .nickname {
      display: none;
    }
    .avatar {
      transform: scale(0.7);
      margin-right: 0;
      transition: all ease 0.5s;
    }
  }

  .el-menu {
    border-right: 0;
  }

  .item-title {
  }

  .menu-icon {
    margin-right: 6px;
  }
}
</style>
