<template>
  <el-container class="layout-container">
    <el-header height="50px" class="layout-header">
      <header-bar></header-bar>
    </el-header>
    <el-container>
      <el-aside :class="['layout-aside', { collapse: isCollapse }]">
        <aside-menu></aside-menu>
      </el-aside>
      <el-main class="layout-main">
        <el-scrollbar>
          <router-view v-slot="{ Component, route }">
            <transition name="fade" mode="out-in" appear>
              <!-- :max="ROUTE_CACHE_MAX_NUM" -->
              <keep-alive v-if="route.meta.keepAlive">
                <component :is="Component" :key="route.path"></component>
              </keep-alive>
              <component v-else :is="Component" :key="route.path"></component>
            </transition>
          </router-view>
        </el-scrollbar>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { ROUTE_CACHE_MAX_NUM } from "@/helpers/constants";
import AsideMenu from "./asideMenu.vue";
import HeaderBar from "./headerBar.vue";
export default {
  components: { AsideMenu, HeaderBar },
  data() {
    return {
      ROUTE_CACHE_MAX_NUM: ROUTE_CACHE_MAX_NUM,
    };
  },
  computed: {
    isCollapse() {
      return this.$store.getters.menuCollapse;
    },
    routeCache() {
      return this.$router;
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.layout-container {
  height: 100vh;
  .layout-aside {
    height: calc(100vh - 50px);
    background: #222d32;
    width: 230px;
    transition: all ease 0.5s;
  }

  .layout-aside.collapse {
    width: 64px;
    transition: all ease 0.5s;
  }

  .layout-header {
    padding: 0;
    z-index: 1;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  }

  .layout-main {
    height: calc(100vh - 50px);
    padding: 0;
    // overflow-y: auto;
    background: #f1f4f6;
  }
}
</style>
